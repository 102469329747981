import React from "react";
import "../App.css";
import { Header, Footer } from "../component/index";
import { Helmet } from "react-helmet";
import Top1 from "../images/carousel/carousel_2.jpg";
import Good4 from "../images/good_point/good_point_4.jpg";
import Good5 from "../images/good_point/good_point_5.jpg";

const Course = () => {
  const title = "Result | ROCK GYM セミパーソナルジム | 姫路";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Header />
      <div className="container">
        <h2 className="page-title">コース</h2>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">セミパーソナル12回コース</p>
            <div className="container-course">
              <img src={Top1} alt="img..." />
            </div>
            <p className="course-description">まず初めに1回60分のセミパーソナル12回コースを受けて頂きます。<br/>このコースでは目的に合わせて今の自分に最適な負荷でトレーニングをおこない、<br/>正しいフォームを身に付けていきます。</p>
            <p className="price">¥39,600<span>（税込）</span></p>
          </div>
        </div>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">セミパーソナル4回券</p>
            <p className="course-description">12回コース修了後、月4回のセミパーソナル回数券です。</p>
            <p className="price">¥8,800<span>（税込）</span></p>
          </div>
        </div>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">セミパーソナル8回券</p>
            <p className="course-description">12回コース修了後、月8回のセミパーソナル回数券です。<br/>10%off!!</p>
            <p className="price">¥15,400<span>（税込）</span></p>
          </div>
        </div>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">パーソナル30 / 45</p>
            <div className="container-course">
              <img src={Good5} alt="img..." />
              <img src={Good4} alt="img..." />
            </div>
            <p className="course-description mb-3">30分または45分のマンツーマンのコースです。<br/>パーソナル限定のメニューをご用意しています。</p>
            <p className="price">¥3,300 / ¥4,400<span>（税込）</span></p>
          </div>
        </div>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">10回券</p>
            <p className="course-description">お好きなパーソナル30 / 45のコースを<br/>自由に組み合わせることができます。<br/>10%off!!</p>
            <div className="price">¥29,700 / ¥39,600<span>（税込）</span></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Course;