import React from "react";
import { Link } from "react-router-dom";
import { Divide as Hamburger } from "hamburger-react";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light navbarBG">
      <div className="container">
        <Link className="navbar-brand main-logo" to="/">ROCK GYM</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <Hamburger  color="#ffeb3b" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mb-2 mb-lg-0 nabBarUl">
            <li className="nav-item">
              <Link className="nav-link nav-sub-link" to="course">コース</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link nav-sub-link" to="result">結果紹介</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link nav-sub-link" to="/storeinfo">店舗情報</Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link nav-sub-link" to="prevention">感染予防対策</Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="case">無料体験申込</Link>
            </li>
            <li className="nav-item tel-contact">
              電話申込はこちら<br/><a href="tel:080-6914-6969" className="tel-contact-link">080-6914-6969</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header;
