import React from "react";
import "../App.css";
import { Header, Footer} from "../component/index";
import OwnerImage from "../images/owner/owner.jpg";
import Top1 from "../images/carousel/carousel_2.jpg";
import Good2 from "../images/good_point/good_point_2.jpg";
import Good4 from "../images/good_point/good_point_4.jpg";
import Good5 from "../images/good_point/good_point_5.jpg";
import GYM from "../images/gym_interior/gym_interior_2.jpg";
import { Link } from "react-router-dom";

const Home = () => {
  return (
      <div className="container">
        <Header />
        <div class="carousel-inner home-area">
          <div>
            <p className="home-text">適度な距離感で、<br/>ほどよいパーソナル</p>
            <p className="home-text2">少人数完全予約<br/>会員制ジム</p>
          </div>
          <div data-bs-interval="6000">
            <img src={Top1} class="d-block w-100 mx-auto top-image" alt="img..."/>
          </div>
        </div>
        <p className="store-name">ROCK GYM</p>
        <div className="message-area">
          <p className="sub-message">「ジム通いが続かない...」<br/>「トレーニング方法がわからない...」<br/>「途中で挫折してしまう...」<br/></p>
          <p className="sub-message sub-message-2">そんな悩みを全て解決します！</p>
        </div>
        <button type="button" class="btn freetry-apply-button">
          <Link to="/case" className="free-apply">無料体験を申し込む</Link>
        </button>
        <div className="tel-contact-home">
          <p>電話申込はこちら　<a href="tel:080-6914-6969">080-6914-6969</a></p>
        </div>
        <div class="rockgym-good">
          <div class="main">
            <div class="text">ROCK GYMのいいところ</div>		
            <div class="shadow">
                <div  class="viewport">
                    <div class="text">ROCK GYMのいいところ</div>
                </div>
            </div>
          </div>
          <div class="flex-item flex-item-first">
            <div class="flex-item__txt">
              <p>
                <span class="good-sub-title"><span class="good-sub-title-no">01</span>ほどよいパーソナルのセミパーソナル</span><br/>
                <br/>
                セミパーソナル60では2~3名に対してトレーナーが1人つきます！！<br/>適度な距離感でほどよいパーソナルが受けられます！！
              </p>
            </div>
            <div class="flex-item__img">
              <img src={Top1} alt="img..."/>
            </div>
          </div>
          <div class="flex-item">
            <div class="flex-item__txt">
              <p><span class="good-sub-title"><span class="good-sub-title-no">02</span>完全オーダーメイドのトレーニングメニュー</span><br/>
              <br/>
              お一人お一人の体力と筋力と目的に合わせたオーダーメイドのトレーニングメニューを作ります！！<br/>筋トレが苦手な方や運動嫌いな方でも大丈夫です！！</p>
            </div>
            <div class="flex-item__img">
              <img src={Good2} alt="img..."/>
            </div>
          </div>
          <div class="flex-item">
            <div class="flex-item__txt">
              <p><span class="good-sub-title"><span class="good-sub-title-no">03</span>種類豊富なトレーニングツール</span><br/>
              <br/>
              ウエイトトレーニングはもちろん、ROCK GYMでしかできない体幹トレーニングや自重トレーニングが体験できます！！</p>
            </div>
            <div class="flex-item__img">
              <img src={GYM} alt="img..."/>
            </div>
          </div>
          <div class="flex-item">
            <div class="flex-item__txt">
              <p><span class="good-sub-title"><span class="good-sub-title-no">04</span>30分の時短パーソナル</span><br/>
              <br/>
              パーソナル30では30分間で目的に合わせたトレーニングやストレッチができます！！短期集中の方にオススメです！！</p>
            </div>
            <div class="flex-item__img">
              <img src={Good4} alt="img..."/>
            </div>
          </div>
          <div class="flex-item">
            <div class="flex-item__txt">
              <p><span class="good-sub-title"><span class="good-sub-title-no">05</span>初心者向けのキックボクシング</span><br/>
              <br/>
              脂肪燃焼・ストレス発散！<br/>初心者の方でも楽しく全身運動で健康的な体作りを始めましょう！</p>
            </div>
            <div class="flex-item__img">
              <img src={Good5} alt="img..." />
            </div>
          </div>
          <div class="flex-item">
            <div class="flex-item__txt">
              <p><span class="good-sub-title"><span class="good-sub-title-no">06</span>優しいトレーナー</span><br/>
              <br/>
              担当者：ROCK GYM代表 平田直之<br/>生年月日：1990年11月26日<br/>トレーナー歴：8年（2020年6月 ROCK GYM開業）<br/>ひとこと：褒めて伸ばすタイプです！！</p>
            </div>
            <div class="flex-item__img">
              <img src={OwnerImage} alt="owner-img" />
            </div>
          </div>
        </div>
        <div className="map">
          <h2 className="mb-5">- 所在地 -</h2>
          <p>〒672-8023<br/>兵庫県姫路市白浜町甲400-14 天マ屋ビル3階</p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.7811014154304!2d134.70374441510228!3d34.786285586137645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3554de23cd8bf297%3A0x3c8666bddd64a978!2z77yI5qCq77yJ5aSp44Oe5bGL!5e0!3m2!1sja!2sjp!4v1628871532143!5m2!1sja!2sjp" allowfullscreen="" loading="lazy" className="map-frame"></iframe>
        </div>
        <Footer />
      </div>
  );
}

export default Home;
